
	import { Component, Prop, Vue } from 'vue-property-decorator';
	import HeadingText from '@pixcap/ui-library/components/Typography/HeadingText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import IconLargeArrowRight from '@pixcap/ui-library/components/Icons/IconLargeArrowRight.vue';
	@Component({
		name: 'ErrorPage',
		components: {
			HeadingText,
			Button,
			IconLargeArrowRight,
		},
	})
	export default class ErrorPage extends Vue {
		@Prop() error: {
			statusCode: number;
			message: string;
			path: string;
		};

		get errorStatusCode() {
			return this.error.statusCode;
		}

		get is404Error() {
			return this.errorStatusCode == 404;
		}

		get is500Error() {
			return this.errorStatusCode == 500;
		}
	}
