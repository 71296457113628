
	type ButtonColor = 'primary' | 'secondary' | 'neutral';
	type ButtonVariant = 'contain' | 'outline';
	enum ButtonSize {
		xlarge = 'xlarge',
		large = 'large',
		medium = 'medium',
		small = 'small',
		xsmall = 'xsmall',
	}
	import { Component, Vue, Prop } from 'vue-property-decorator';
	@Component({
		name: 'ButtonIcon',
	})
	export default class ButtonIcon extends Vue {
		@Prop({ default: 'contain' }) readonly variant: ButtonVariant;
		@Prop({ default: 'primary' }) readonly color: ButtonColor;
		@Prop({ default: ButtonSize.medium }) readonly size: ButtonSize;
		@Prop() readonly tooltip: string;

		get className() {
			return [`btn-icon-variant--${this.variant}`, `btn-icon-color--${this.color}`, `btn-icon-size--${this.size}`];
		}
	}
