
	import { Component, Vue, Prop } from 'vue-property-decorator';
	import Icon from '@pixcap/ui-library/common/Icon.vue';
	import IconLoader from '@pixcap/ui-library/components/Icons/IconLoader.vue';
	@Component({
		name: 'Button',
		components: {
			Icon,
			IconLoader,
		},
	})
	export default class Button extends Vue {
		@Prop() readonly btnText: string;
		@Prop() readonly btnStyle: string;
		@Prop() readonly size: 'lg' | 'xlarge' | 'small' | 'xsmall' | null;
		@Prop() readonly btnColor: 'danger';
		@Prop() readonly disabled: boolean;
		@Prop() readonly shadow: 'l1' | 'l2' | 'l3';
		@Prop() readonly icon: string; // Icon name, capitalized
		@Prop() readonly iconColor: string;
		@Prop() readonly type: 'icon-only' | null;
		@Prop({ default: 'button' }) readonly buttonType: 'submit' | 'button' | 'reset';
		@Prop() readonly width: number | string;
		@Prop() readonly loading: string; // add loading message

		iconsWidths = { lg: 20, xlarge: 16, small: 12, xsmall: 10 };

		get hasRightIcon() {
			return this.$slots.rightIcon || this.$scopedSlots.rightIcon;
		}

		get hasLeftIcon() {
			return this.$slots.leftIcon;
		}

		get isDisabled() {
			return this.disabled || (this.loading ? true : false);
		}

		get classes(): string {
			let classes = 'btn';
			if (this.btnStyle) classes += ` btn--${this.btnStyle}`;
			if (this.btnColor) classes += ` btn--color-${this.btnColor}`;
			if (this.size) classes += ` btn--${this.size}`;
			if (this.type) classes += ` btn--${this.type}`;
			if (this.shadow) classes += ` btn--shadow-${this.shadow}`;
			return classes;
		}

		get widthStyle() {
			let style: any = {};
			const width = this.width;
			if (!!width) {
				style.width = typeof width === 'number' ? `${width}px` : width;
			}
			return style;
		}

		get btnDisplayText() {
			if (this.loading) return this.loading;
			return this.btnText;
		}

		get iconWidth() {
			return this.size ? this.iconsWidths[this.size] : 16;
		}

		handleMouseDown(e) {
			if (this.isDisabled) return;
			this.$emit('handleMouseDown', e);
		}

		handleMouseDownLeft(e) {
			if (this.isDisabled) return;
			this.$emit('handleMouseDownLeft', e);
		}

		handleClick(e) {
			if (this.isDisabled) return;
			this.$emit('handleClick', e);
		}
	}
